import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Page extends React.Component {
  render() {
    return (
      <Layout title="404 - Seite nicht gefunden">
        <SEO title="404 - Seite nicht gefunden" />

        <p>Die gewünschte Seite konnte nicht gefunden werden.</p>
        <p>
          <Link to="/">Zur Startseite</Link> oder zur{" "}
          <Link to="/sitemap/">Sitemap</Link>
        </p>
      </Layout>
    )
  }
}

export default Page
